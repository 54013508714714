import React, { Component } from 'react'
import BgImage from '../../images/ph-le-le.png';
// import BgImage2 from '../../images/le-title-2.png';
import './index.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class Header extends Component
{
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div className={'header'}>
                {/*<img src={LogoWordmark}  className={'header logo-img'} alt={'background'} />*/}

                <Slider {...settings}>
                    <div>
                        <img src={BgImage}  className={'header bg-img'} alt={'background'} />
                    </div>
                    {/*<div>*/}
                    {/*    <img src={BgImage2}  className={'header bg-img'} alt={'background'} />*/}
                    {/*</div>*/}
                </Slider>
            </div>
        );
    }
}