import React, { Component } from 'react'
import { getHost } from '../../util/browser';
import Dapp from '../Dapp';
import './index.css';

export default class History extends Component {
    state = {
        History: [],
    }

    componentDidMount() {
        if (window.__mmHistory) {
            this.setState({ History: window.__mmHistory });
        }
    }


    renderHistory() {
        return (
            <div className={'favorites'}>
                {
                    this.state.History.map((dapp, i) => (
                        getHost(dapp.url) != "home.lemoon.cash"?<Dapp
                            data={{
                                ...dapp,
                                icon: `https://api.faviconkit.com/${getHost(dapp.url)}/64`,
                                description: null
                            }}
                            key={`fav-${dapp.url}`}
                            size={'small'}
                            onClose={this.onClose}
                            position={i}
                        />:null
                    ))
                }
            </div>
        );
    }

    renderEmpty() {
        return (
            <div className={'favorites-empty'}>
                <p>You have no history yet</p>
            </div>
        );
    }

    render() {
        if (!this.state.History || !this.state.History.length) {
            return this.renderEmpty();
        }

        return this.renderHistory()
    }
}
